<template>
  <div class="exchange">
    <div class="top_bar">
      <div class="top_bar_icon" @click="handleRouter">
        <van-icon name="arrow-left" color="#7e7e7e" size="16"/>
      </div>
      <div>填写订单</div>
    </div>
    <div class="content">
      <div class="item">
        <div class="item_left">商品名称：</div>
        <div class="item_right_text">{{ detail.goods_name }}</div>
      </div>
      <div class="item">
        <div class="item_left">金额&规格：</div>
        <div class="item_right">
          <span class="item_right_num">{{ detail.shop_price }}</span>
          <span class="item_right_text">/{{ detail.spu }}</span>
        </div>
      </div>
      <!-- <div class="item">
        <div class="item_left">库存：</div>
        <div class="item_right_text">{{ detail.store_count }}</div>
      </div> -->

      <div class="item">
        <div class="item_left">数量：</div>
        <div class="item_right_text">
          <van-stepper v-model="buyCount" min="1" :max="detail.store_count" />
        </div>
      </div>

       <div class="item">
        <van-field label="收货人姓名：" :border="false" v-model="consignee_name" placeholder="请填写收货人姓名" />
        <!-- <div class="item_left">收货人姓名：</div>
        <div class="item_right_text">
          <van-cell-group>
            <van-field
                v-model="consignee_name"
                label-width="120"
                :placeholder="'请填写收货人姓名'"
                :border="false"
            />
          </van-cell-group>
        </div> -->
      </div>
       <div class="item">
        <van-field label="收货人电话：" :border="false" v-model="consignee_mobile" placeholder="请填写收货人联系方式" />
        <!-- <div class="item_left"></div>
        <div class="item_right_text">
          <van-cell-group>
            <van-field
                v-model="consignee_mobile"
                label-width="120"
                :placeholder="'请填写收货人联系方式'"
                :border="false"
            />
          </van-cell-group>
        </div> -->
      </div>
      <div class="item">
        <van-field label="收货地址：" :border="false" v-model="address" placeholder="请填写您的收货地址" />
        <!-- <div class="item_left">收货地址：</div>
        <div class="item_right_text">
          <van-cell-group>
            <van-field
                v-model="address"
                label-width="120"
                :placeholder="'请填写您的收货地址'"
                :border="false"
            />
          </van-cell-group>
        </div> -->
      </div>
      <div class="item">
        <van-field label="备注：" :border="false" v-model="note" placeholder="请填写您的备注" />
        <!-- <div class="item_left">备注：</div>
        <div class="item_right_text">
          <van-cell-group>
            <van-field
                v-model="note"
                label-width="120"
                :placeholder="'请填写您的备注'"
                :border="false"
            />
          </van-cell-group>
        </div> -->
      </div>
    </div>
    <div class="footer">
      <!-- <div class="btn" @click="handleShow">{{ detail.status == 1 ? "申请积分" : "立即兑换" }}</div> -->
      <div class="btn" @click="handleBuy">立即购买</div>
    </div>
    <!-- 弹框 -->
    <!-- <van-overlay :show="show" @click="show = false">
      <div class="exchange_popup">
        <div class="popup_img" @click="show = false">
          <img src="../../assets/image/close.svg" alt/>
        </div>
        <div class="popup_content" :class="[detail.status==1?'':'content_h']" @click.stop>
          <div class="popup_title">{{ detail.status == 1 ? "上级代理商" : "提示" }}</div>
          <div class="popup_name" v-show="detail.status==1">
            <span class="popup_name_left">代理商姓名：</span>
            <span>{{ message.name }}</span>
          </div>
          <div class="popup_phone" v-show="detail.status==1">
            <span>联系电话：</span>
            <span class="popup_phone_right">{{ message.phone }}</span>
          </div>
          <div v-show="detail.status==2" class="popup_prompt">是否立即兑换商品？</div>
          <a v-if="detail.status==1" class="btn btn_w" :href="'tel:'+message.phone">申请积分</a>
          <div v-else class="btn btn_w" @click="handleResult">立即兑换</div>
        </div>
      </div>
    </van-overlay> -->
  </div>
</template>
<script>

export default {
  data() {
    return {
      address: '',
      show: false,
      goods_id: "",
      detail: {},
      message: {},
      consignee_mobile:'',
      consignee_name:'',
      note:'',
      buyCount:1, //默认购买1个
      allPrice: 0,
      defaultAddress: {},
    };
  },

  methods: {
    handleRouter() {
      this.$router.go(-1);
    },
    // handleShow() {
    //   this.show = true;
    // },
    async handleBuy() {
      this.allPrice = this.detail.shop_price_num * this.buyCount;
      const data = await this.$api.getPay({
        goods_id: this.goods_id,
        address: this.address,
        consignee_name:this.consignee_name,
        consignee_mobile:this.consignee_mobile,
        note:this.note,
        total_price: this.allPrice,
        total_num: this.buyCount,
      });
      if (data.data.code == 1) {
        this.wxReadyToPay(data.data.data);
      }else{
        this.$notify({ type: "danger", message: data.data.msg });
      }
      
      // console.log(data, type);
      // console.log(data.data.status, ",,,status");
    },

    wxReadyToPay(options) {
      let that = this;
      // console.log(typeof window.WeixinJSBridge);debugger;
      if (typeof window.WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            that.onBridgeReady(options),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent(
            "WeixinJSBridgeReady",
            that.onBridgeReady(options)
          );
          document.attachEvent(
            "onWeixinJSBridgeReady",
            that.onBridgeReady(options)
          );
        }
      } else {
        that.onBridgeReady(options);
      }
    },

    ///////
    onBridgeReady(options) {
      // console.log(options);debugger;
      // let that = this;
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: options.appId, //公众号名称，由商户传入
          timeStamp: options.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: options.nonceStr, //随机串
          package: options.package,
          signType: "MD5", //微信签名方式：
          paySign: options.paySign //微信签名
        },
        function(res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // this.$toast.success("支付成功！");
            let string = window.location.href;
            let str = "#";
            var str_before = string.split(str)[0];
            window.location.href = `${str_before}#/success`;
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          }
        }
      );
    },

    async handleResult() {
      // if(!this.consignee_mobile){
      //   this.Toast.fail('请输入正确的电话号码');
      //   return
      // }
      // if(!this.consignee_name){
      //   this.Toast.fail('请输入收货人姓名');
      //   return
      // }
      const data = await this.$api.pay({
        goods_id: this.goods_id,
        address: this.address,
        consignee_name:this.consignee_name,
        consignee_mobile:this.consignee_mobile,
        note:this.note
      });
      if (data.data.code === 1) {
        this.$router.push({
          path: "/result/index",
          query: {
            success: true
          }
        });
      } else {
        this.$router.push({
          path: "/result/index",
          query: {
            success: false
          }
        });
      }
      console.log(data);
      // this.$router.push({
      //   path: "/result/index"
      // });
    },
    async handleBuyNow() {
      const data = await this.$api.buyNow({
        goods_id: this.goods_id
      });
      if (data.data.code === 1) {
        this.detail = data.data.data;
      } else {
        this.$notify({type: "danger", message: data.data.msg});
      }
    },
    async handleGetPhone() {
      const data = await this.$api.getPhone({
        // goods_id: this.goods_id
      });
      if (data.data.code === 1) {
        this.message = data.data.data;
      } else {
        this.$notify({type: "danger", message: data.data.msg});
      }
    },

    async handleGetUserDefaultAddress() {
      const data = await this.$api.handleGetUserDefaultAddress({
        // goods_id: this.goods_id
      });
      if (data.data.code === 1) {
        this.defaultAddress = data.data.data;
        this.consignee_name = this.defaultAddress.consignee;
        this.consignee_mobile = this.defaultAddress.mobile;
        this.address = this.defaultAddress.address;
        this.note = this.defaultAddress.note;
      }
    },
  },
  mounted() {
    this.goods_id = this.$route.query.id;
    this.handleBuyNow();
    this.handleGetPhone();
    this.handleGetUserDefaultAddress();
  }
};
</script>
<style lang="scss" scoped>
.exchange {
  width: 375px;
  padding-top: 40px;
  box-sizing: border-box;

  .content {
    .item {
      width: 351px;
      height: 49px;
      border-bottom: 1px solid #ebebeb;
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      color: rgba(102, 102, 102, 1);
      margin: 0 auto;
      align-items: center;

      .item_left {
      }

      .item_right_text {
        color: rgba(51, 51, 51, 1);
      }

      .item_right {
        .item_right_num {
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #fe3131;
        }

        .item_right_text {
          color: #999;
        }
      }

      .item_right_price {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(254, 49, 49, 1);
      }
    }
  }

  .footer {
    margin-top: 198px;
  }

  .exchange_popup {
    position: relative;

    .popup_img {
      width: 22px;
      height: 22px;
      position: absolute;
      top: 104px;
      right: 32px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .popup_content {
      width: 310px;
      height: 250px;
      background: rgba(255, 255, 255, 1);
      border-radius: 6px;
      position: absolute;
      top: 136px;
      right: 32px;
      padding-top: 20px;
      box-sizing: border-box;

      .popup_title {
        text-align: center;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 20px;
      }

      .popup_name {
        margin-top: 42px;
        line-height: 22px;
        font-size: 16px;
        color: #333;
        padding-left: 66px;
        box-sizing: border-box;

        .popup_name_left {
          color: #999;
        }
      }

      .popup_phone {
        font-size: 16px;
        color: rgba(153, 153, 153, 1);
        line-height: 22px;
        margin-top: 25px;
        padding-left: 66px;
        box-sizing: border-box;

        .popup_phone_right {
          color: #327dfb;
        }
      }

      .popup_prompt {
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
        line-height: 22px;
        position: absolute;
        top: 82px;
        left: 83px;
      }

      .btn_w {
        position: absolute;
        width: 210px;
        bottom: 26px;
        left: 50px;
      }
    }

    .content_h {
      height: 210px;
    }
  }
}
</style>
